import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Certificates power modern software. Kubernetes, service meshes, containers, microservices, distributed databases & queues, configuration management & orchestration systems, BeyondCorp proxies, and secure software supply chains all use certificates. Organizations running modern infrastructure make extensive use of certificates, whether they're aware of it or not.`}</p>
    <p>{`For many organizations, this is a blind spot, with long-lived certificates issued manually or from poorly secured deploy pipelines. Secure, reliable certificate management requires automation. Smallstep Certificate Manager is a flexible, extensible toolchain with all of the server and client-side components you'll need to solve this problem, carefully designed to be operationally simple, easy to use, and hard to misuse.`}</p>
    <p>{`Certificate Manager builds on two open source projects, maintained by smallstep:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`step-ca`}</inlineCode>{`: a private online certificate authority for secure automated certificate management.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`step`}</inlineCode>{`: a general-purpose cryptography toolkit and the client-side counterpart to `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`Core Concepts & Components`}</h2>
    <h3>{`Certificates, CAs, Subjects, Endpoints, and Relying Parties`}</h3>
    <p>{`A `}<strong parentName="p">{`certificate`}</strong>{` is a sort of credential. Concretely, a certificate is a data structure that contains a name, public key, validity period (a.k.a., lifetime), and additional metadata. Certificates are `}<strong parentName="p">{`signed`}</strong>{` by a trusted `}<strong parentName="p">{`certificate authority`}</strong>{` (CA) and issued to `}<strong parentName="p">{`endpoints`}</strong>{`. A `}<strong parentName="p">{`relying party`}</strong>{` validates a certificate and extracts the `}<strong parentName="p">{`subject name`}</strong>{` to authenticate the identity of an endpoint.`}</p>
    <h2>{`Endpoints`}</h2>
    <p>{`An `}<strong parentName="p">{`Endpoint`}</strong>{` is an entity (person, device, workload) that is issued a certificate. Certificate Manager attempts to group certificate renewals for an Endpoint for alerting, audit, and billing purposes.`}</p>
    <p>{`Certificate Manager can be configured to alert you when an Endpoint’s last certificate is approaching expiry and has not been renewed.`}</p>
    <p>{`The Certificate Manager dashboard provides an Endpoint detail view (coming soon) that makes it easy for administrators to view the historical certificate lineage for an Endpoint. This aggregated view simplifies operations, auditing, reporting, and compliance inquiries.`}</p>
    <p>{`Certificate Manager billing is also metered on Endpoint-months.`}</p>
    <ul>
      <li parentName="ul">{`Billing starts when a certificate is issued for a new Endpoint`}</li>
      <li parentName="ul">{`Billing ends when an Endpoint’s last certificate expires or is revoked`}</li>
    </ul>
    <p>{`Billing per-certificate would penalize deployments that use short-lived certificates and automated renewal. Endpoint billing is designed to encourage this best practice.`}</p>
    <p>{`Two Endpoint examples:`}</p>
    <ol>
      <li parentName="ol">{`A single device with `}<strong parentName="li">{`one 30-day certificate`}</strong>{` would be billed at the same rate as,`}</li>
      <li parentName="ol">{`A single device with `}<strong parentName="li">{`60 one-day certificates`}</strong>{` renewed every 12-hours. `}</li>
    </ol>
    <p><strong parentName="p">{`Endpoint`}</strong>{` grouping is automatic and intuitive for most use cases:`}</p>
    <ul>
      <li parentName="ul">{`For provisioners with renewal enabled:`}
        <ul parentName="li">
          <li parentName="ul">{`Certificates issued using  `}<inlineCode parentName="li">{`step ca certificate`}</inlineCode>{` (or any other method that uses the `}<inlineCode parentName="li">{`/sign`}</inlineCode>{` API) create a new Endpoint`}</li>
          <li parentName="ul">{`Certificates issued using `}<inlineCode parentName="li">{`step ca renew`}</inlineCode>{` (or any other method that uses the `}<inlineCode parentName="li">{`/renew`}</inlineCode>{` API) are associated with the existing Endpoint of the certificate that’s being renewed`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For provisioners with renewal disabled, common with ACME and OIDC, certificates with identical subjects (common name and SANs), ignoring order and capitalization, belong to the same Endpoint`}</li>
    </ul>
    <p>{`For billing purposes, there is a limit of three active certificates per Endpoint. Each active certificate above three is billed as an additional Endpoint. To avoid being charged for multiple Endpoints you can revoke unused certificates after they’ve been renewed.`}</p>
    <p>{`If automatic Endpoint grouping does not work for your use case, you can also specify an `}<strong parentName="p">{`Endpoint ID`}</strong>{` when you request a certificate (coming soon).`}</p>
    <p>{`If you’d like to opt-in to certificate-based billing, inquire about high-volume fixed pricing, or have any other questions about Endpoint-based billing for your use case please `}<a parentName="p" {...{
        "href": "mailto:support@smallstep.com"
      }}>{`contact us`}</a>{`.`}</p>
    <h2>{`Authorities`}</h2>
    <p>{`Certificate Manager `}<strong parentName="p">{`authorities`}</strong>{` are trusted services that manage certificate issuance and revocation. Several authority types are supported. Authorities are highly configurable, and multiple authorities can be used together to meet complex requirements.`}</p>
    <h3>{`Issuing Authorities`}</h3>
    <p>{`An `}<strong parentName="p">{`issuing authority`}</strong>{` is an online certificate authority that authenticates and authorizes certificate requests and issues certificates. Issuing authorities sign certificates themselves. To do so, they are provisioned with a special CA certificate and private key.`}</p>
    <p>{`There are two types of issuing authority:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Hosted`}</strong>{`: run by smallstep on your behalf as part of your Certificate Manager account.`}</li>
      <li parentName="ol"><strong parentName="li">{`Linked`}</strong>{`: an instance of `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` you run that connects to your Certificate Manager account for reporting, alerting, revocation, and other managed services.`}</li>
    </ol>
    <p>{`If an issuing authority's private key is compromised, it can be used to maliciously issue certificates that will be trusted by the rest of your infrastructure. To protect these keys, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` integrates with hardware and software key managers, including PKCS#11 Hardware Security Modules (HSMs), YubiKeys, and cloud key management systems (KMSs) from AWS, GCP, and Azure. For hosted authorities, smallstep secures these keys for you in GCP's CloudKMS, with options for software or FIPS 140-2 Level 3 hardware protection levels.`}</p>
    <h3>{`Root vs. Intermediate Certificate Authorities`}</h3>
    <p>{`A `}<strong parentName="p">{`root CA`}</strong>{` is `}<em parentName="p">{`directly`}</em>{` trusted by relying parties. The root CA's certificate must be deployed to VMs, devices, and containers, then software and systems must be configured to trust it. We'll see how this is done later when we discuss `}<em parentName="p">{`bootstrapping trust`}</em>{`.`}</p>
    <p>{`An `}<strong parentName="p">{`intermediate CA`}</strong>{` (also called a `}<strong parentName="p">{`subordinate CA`}</strong>{`) is issued a special certificate that authorizes it to sign other certificates. Endpoint certificates can be signed directly by the root or, more typically, by an intermediate CA that recursively `}<em parentName="p">{`chains up`}</em>{` to the root CA. An intermediate CA allows you to keep your root signing keys offline, which improves security.`}</p>
    <p>{`For most scenarios, this is a nuance you won't have to worry about. By default, Certificate Manager will create a non-issuing root CA and a separate issuing authority for you automatically. For advanced scenarios, you can use your own offline root CA, provision multiple issuing authorities from a single root, configure multiple levels of intermediates, and revoke and re-issue intermediate authority certificates as necessary.`}</p>
    <h3>{`Registration Authorities`}</h3>
    <p>{`Like an issuing authority, a `}<strong parentName="p">{`registration authority`}</strong>{` is an online service that accepts and authenticates certificate requests. But, instead of issuing certificates itself, a registration authority passes authentic requests to an issuing authority to sign. Registration authorities support all Provisioner types and are an optional component. They deliver most of the benefits of a linked issuing authority with less operational complexity since there's no signing key to manage.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/ia-cloud-ra-challenge-client.svg",
        "alt": "ACME Registration Authority",
        "title": "In this example, the Smallstep ACME RA runs within the local network or VPC and responds to ACME requests from internal infrastructure and workloads. The RA accepts ACME orders and authenticates requests by verifying an ACME challenge. Upon verification, a CSR is securely passed to the Issuing Authority to sign and catalog."
      }}></img></p>
    <h3>{`Validation Authorities`}</h3>
    <p>{`A `}<strong parentName="p">{`validation authority`}</strong>{` distributes certificate revocation status. Validation authorities implement two open standards to support active revocation:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Certificate Revocation List (CRL)`}</strong>{`: a signed, immutable ledger that lists the serial number of all revoked certificates. CRLs are signed by trusted infrastructure and served from cloud storage for high performance and availability.`}</li>
      <li parentName="ul"><strong parentName="li">{`Online Certificate Status Protocol (OCSP)`}</strong>{`: a standard API for requesting the revocation status of a particular certificate. The Certificate Manager OCSP responder is a shared-nothing service that uses CRLs as a data source.`}</li>
    </ul>
    <h3>{`Provisioners`}</h3>
    <p><strong parentName="p">{`Provisioners`}</strong>{` are used by issuing authorities to authenticate certificate requests. Provisioners make it easy to automate certificate management where possible, and support semi-automated / self-serve workflows where required.`}</p>
    <p>{`Certificate lifetimes, access control policies, renewal, templates, and many other options are configurable per-provisioner. Since an issuing authority can have multiple provisioners, you implement complex authentication and authorization policies and issue different kinds of certificates from one issuing authority.`}</p>
    <p>{`Some of the more popular workflows provisioners enable include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Passwords`}</strong>{`: in its simplest form, the `}<inlineCode parentName="li">{`JWK`}</inlineCode>{` provisioner can be used to get a certificate using a password. We'll see an example of this below.`}</li>
      <li parentName="ul"><strong parentName="li">{`One-time tokens`}</strong>{`: the `}<inlineCode parentName="li">{`JWK`}</inlineCode>{` provisioner also supports one-time tokens using `}<a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli/reference/ca/token"
        }}><inlineCode parentName="a">{`step ca token`}</inlineCode></a>{`, which can be generated by orchestration or configuration management and passed to a container or host to obtain a certificate.`}</li>
      <li parentName="ul"><strong parentName="li">{`ACME`}</strong>{`: the `}<inlineCode parentName="li">{`ACME`}</inlineCode>{` provisioner implements the `}<a parentName="li" {...{
          "href": "https://datatracker.ietf.org/doc/html/rfc8555"
        }}>{`ACME standard`}</a>{` created by Let's Encrypt. It can be used to automatically get a certificate for a domain name or IP address. A rich client ecosystem and built-in support in many tools makes ACME easy to integrate. See the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/acme"
        }}>{`ACME documentation`}</a>{` to learn more.`}</li>
      <li parentName="ul"><strong parentName="li">{`Single Sign-on`}</strong>{`: The `}<inlineCode parentName="li">{`OIDC`}</inlineCode>{` provisioner uses `}<a parentName="li" {...{
          "href": "https://datatracker.ietf.org/doc/html/rfc6749"
        }}>{`OAuth`}</a>{` and `}<a parentName="li" {...{
          "href": "https://openid.net/specs/openid-connect-core-1_0.html"
        }}>{`OpenID Connect (OIDC)`}</a>{` to get a certificate using single sign-on via Google, Okta, Azure AD, or any other compatible identity provider. See the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/oidc"
        }}>{`OIDC documentation`}</a>{` to learn more. There are two common scenarios where this is useful:`}
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Authenticating users`}</strong>{` (engineers, operators, etc.) who need a certificate for code signing or to authenticate to databases, services, or other infrastructure using mutual TLS.`}</li>
          <li parentName="ol"><strong parentName="li">{`Self-serve / semi-automated`}</strong>{` workflows for administrators to obtain certificates for workloads, devices, and other infrastructure, where automation is not possible.`}</li>
        </ol>
      </li>
      <li parentName="ul"><strong parentName="li">{`Cloud VMs`}</strong>{`: the `}<inlineCode parentName="li">{`IID`}</inlineCode>{` provisioner can be used to get certificates to your VMs running on AWS, GCP, or Azure.`}</li>
      <li parentName="ul"><strong parentName="li">{`Kubernetes`}</strong>{`:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/jetstack/cert-manager"
            }}>{`cert-manager`}</a>{` is a popular ACME client for Kubernetes. It creates `}<a parentName="li" {...{
              "href": "https://kubernetes.io/docs/concepts/configuration/secret/#tls-secrets"
            }}>{`certificate secret resources`}</a>{` that can be used by containers and ingresses. `}<a parentName="li" {...{
              "href": "https://github.com/smallstep/step-issuer"
            }}><inlineCode parentName="a">{`step-issuer`}</inlineCode></a>{` lets you connect `}<inlineCode parentName="li">{`cert-manager`}</inlineCode>{` to an issuing authority.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/smallstep/autocert"
            }}>{`autocert`}</a>{` lets you use pod annotations to automatically inject certificates into containers using volume mounts.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`k8ssa`}</inlineCode>{` provisioner lets you get certificates using Kubernetes service accounts.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Devices`}</strong>{`: The `}<inlineCode parentName="li">{`X5C`}</inlineCode>{` and `}<inlineCode parentName="li">{`SSHPOP`}</inlineCode>{` provisioners let you get a certificate using an existing x509 or SSH certificate issued from another authority. This can be used by devices to exchange long-lived `}<em parentName="li">{`birth certificates`}</em>{` issued at manufacture time for short-lived `}<em parentName="li">{`workload certificates`}</em>{` and for other `}<em parentName="li">{`derived credential`}</em>{` workflows where a certificate from a canonical CA is used to automatically obtain certificates from one or more special-purpose CA(s).`}</li>
    </ul>
    <h2>{`Certificate Lifecycle Management`}</h2>
    <h3>{`Bootstrapping Trust`}</h3>
    <p>{`Before issuing a certificate, your software and systems must be configured to trust your authorities. More precisely, you need to configure everything to trust your `}<em parentName="p">{`root certificate`}</em>{` (i.e., the certificate that belongs to your root CA). You can include your root certificate in base images, distribute it using configuration management, or use `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/ca/bootstrap"
      }}><inlineCode parentName="a">{`step ca bootstrap`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/ca/root"
      }}><inlineCode parentName="a">{`step ca root`}</inlineCode></a>{` to securely download your root certificate from an issuing authority. You can also download the root certificate for your authorities from the Certificate Manager dashboard.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli/reference/ca/bootstrap"
        }}><inlineCode parentName="a">{`step ca bootstrap`}</inlineCode></a>{` configures `}<inlineCode parentName="li">{`step`}</inlineCode>{` to trust your root CA and use a particular issuing authority.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/docs/step-cli/reference/ca/root"
        }}><inlineCode parentName="a">{`step ca root`}</inlineCode></a>{` securely downloads your root certificate.`}</li>
    </ul>
    <p>{`Once you've downloaded your root certificate, you can  add it to your system trust store using `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/certificate/install"
      }}><inlineCode parentName="a">{`step certificate install`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca root root.crt
The root certificate has been saved in root.crt.

$ step certificate install root.crt
Password:
Certificate r.crt has been installed.
X.509v3 Root CA Certificate (ECDSA P-256) [Serial: 1829...9147]
  Subject:     Root CA
  Issuer:      Root CA
  Valid from:  2020-09-18T20:55:03Z
          to:  2030-09-16T20:55:03Z
`}</code></pre>
    <p>{`Or configure software to trust it (see `}<a parentName="p" {...{
        "href": "https://smallstep.com/hello-mtls"
      }}>{`Hello mTLS`}</a>{` for more examples):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat nginx.conf
server {
    listen                 443 ssl;
    server_name            myserver.internal.net;
    ssl_client_certificate /etc/nginx/client_certs/root.crt;
    ssl_verify_client      optional;
    location / {
      if ($ssl_client_verify != SUCCESS) {
        return 403;
      }
    }
}
`}</code></pre>
    <h3>{`Issuing Certificates`}</h3>
    <p><strong parentName="p">{`Issuing`}</strong>{` a certificate using `}<inlineCode parentName="p">{`step`}</inlineCode>{` is simple. In it's most basic form, the `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` command provides simple interactive workflows for people to get a certificate from an issuing authority. Flags and options make scripting and other non-interactive workflows easy. We'll use several variations of this command in the examples below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate example.com example.crt example.key
Use the arrow keys to navigate: ↓ ↑ → ←
What provisioner key do you want to use?
  ▸ mike@example.com (JWK) [kid: -6v0dwnSlz2D5opq-B5prQmyD9DhzdJSHMidiu1TjYg]
    sshpop (SSHPOP)
    x5c (X5C)
    Google (OIDC) [client: 650445034027-jsjdrkiskeq9ke99ud2rqkst82ft8uch.apps.googleusercontent.com]
✔ Provisioner: mike@smallstep.com (JWK) [kid: -6v0dwnSlz2D5opq-B5prQmyD9DhzdJSHMidiu1TjYg]
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://ca.example.com
✔ Certificate: example.crt
✔ Private Key: example.key
`}</code></pre>
    <h3>{`Templates`}</h3>
    <p><strong parentName="p">{`Templates`}</strong>{` give you granular control over certificate details. By default, Certificate Manager is tuned to issue short-lived certificates for use with TLS. Templates let you customize every detail of a certificate, down to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Object_identifier"
      }}>{`OID`}</a>{`, to support any use case.`}</p>
    <p>{`Concretely, a template is a JSON representation of a certificate that's materialized using Go's `}<a parentName="p" {...{
        "href": "https://golang.org/pkg/text/template/"
      }}><inlineCode parentName="a">{`text/template`}</inlineCode></a>{` module and `}<a parentName="p" {...{
        "href": "http://masterminds.github.io/sprig/"
      }}>{`sprig functions`}</a>{`. They look like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/templates-screenshot.svg",
        "alt": "Certificate Templates",
        "title": "Certificate Templates"
      }}></img></p>
    <p>{`Context from certificate requests and authentication credentials are made available as template variables, so you can adjust certificate details based on who's requesting the certificate.`}</p>
    <h3>{`Inventories`}</h3>
    <p><strong parentName="p">{`Inventories`}</strong>{` are lists of hosts, services, people, or any other endpoint. An inventory record lets you associate metadata with an endpoint. Inventories work together with templates. An inventory lookup can be triggered by a certificate request. Metadata is exposed as template variables, where it can be used to customize certificates or authorize certificate requests.`}</p>
    <p>{`For example, you can use inventories to map the email address from an OIDC identity token to a username, authorize a host to get certificates for its workloads, or map an authenticated device ID from a TPM attestation certificate to a DNS name.`}</p>
    <h3>{`Renewal`}</h3>
    <p>{`Certificates expire. Certificate Manager makes renewing a certificate ahead of expiration easy. Renewals are authenticated using your existing certificate, and produce an identical certificate with a new serial number and extended lifetime.`}</p>
    <p>{`In its most primitive form, renewal is a simple single-command operation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`step ca renew svc.crt svc.key
`}</code></pre>
    <p>{`More than a dozen command-line flags make `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` flexible and easy to integrate into almost any operational environment. For a taste of what's possible, consider:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`step ca renew --daemon --exec "nginx -s reload" svc.crt svc.key
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`--daemon`}</inlineCode>{` flag starts a long-running process that will continuously renew `}<inlineCode parentName="p">{`svc.crt`}</inlineCode>{` after 2/3 of its lifetime has elapsed. The `}<inlineCode parentName="p">{`--exec "nginx -s reload"`}</inlineCode>{` option tells Nginx to reload `}<inlineCode parentName="p">{`svc.crt`}</inlineCode>{` after each renewal. We even take care of retries if renewal fail (with exponential backoff and jitter to mitigate denial of service issues and thundering herds).`}</p>
    <p><inlineCode parentName="p">{`step ca renew`}</inlineCode>{` is designed to integrate nicely with `}<inlineCode parentName="p">{`cron`}</inlineCode>{` or `}<inlineCode parentName="p">{`systemd`}</inlineCode>{`, which is what we recommend for production deploys. For more details see our `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-ca/certificate-authority-server-production#renewal-using-systemd-timers"
      }}>{`documentation on production-ready automated renewal`}</a>{`.`}</p>
    <p>{`It's also common to trigger renewal from configuration management or from a container sidecar. Regardless, `}<inlineCode parentName="p">{`step`}</inlineCode>{` makes renewal simple and secure.`}</p>
    <p>{`Finally, sometimes you `}<em parentName="p">{`don't`}</em>{` want certificates to be renewed. For example, if you're issuing certificates using single sign-on (the `}<inlineCode parentName="p">{`OIDC`}</inlineCode>{` provisioner), you probably want your users to re-authenticate with your identity provider periodically to get a new certificate instead of renewing. Renewal can be enabled and disabled per-provisioner to accommodate this (it's disabled by default for the `}<inlineCode parentName="p">{`OIDC`}</inlineCode>{` provisioner).`}</p>
    <h3>{`Revocation`}</h3>
    <p>{`Certificates should be revoked if they're compromised or no longer needed. Smallstep Certificate Manager supports both `}<em parentName="p">{`passive`}</em>{` and `}<em parentName="p">{`active`}</em>{` revocation.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Passive revocation`}</strong>{` disables renewal for a particular certificate. The certificate will be trusted until it expires.`}</li>
      <li parentName="ul"><strong parentName="li">{`Active revocation`}</strong>{` explicitly invalidates a certificate ahead of expiry. CRL and OCSP are used to distribute revocation status to relying parties.`}</li>
    </ul>
    <p>{`Passive revocation is on by default and works well in conjunction with short-lived certificates. Active revocation is often necessary for incident response. However, caching, availability, and configuration challenges can make active revocation unreliable. For systems that support it, we've found that revoking authorization can be easier to implement and more reliable than active certificate revocation. We've covered this topic in more detail `}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/passive-revocation/"
      }}>{`on our blog`}</a>{`.`}</p>
    <h3>{`Observability & Alerting`}</h3>
    <p>{`Certificate Manager is designed to support large, rapidly evolving infrastructures. In these environments, it's easy for a small, unnoticed failure to cascade into a large outage.`}</p>
    <p>{`Certificate Manager can alert you ahead of certificate expiry via email or by calling a webhook. Alerts can be fed into your SIEM or sent to slack so you can remediate ahead of an outage. Issued certificates are catalogued for easy auditability. The Certificate Manager interface allows you to sort and filter, and quickly list and export certificate subjects, issue & expiry dates, issuing authority, provisioner, and other certificate details.`}</p>
    <h2>{`Certificate Manager in Action`}</h2>
    <p>{`Let's look at how these features come together in a few common workflows.`}</p>
    <p>{`To configure `}<inlineCode parentName="p">{`step`}</inlineCode>{` to use our authority, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`step ca bootstrap \\
  --ca-url https://prod.yourco.ca.smallstep.com
  --fingerprint 6d04ff00618125114b97870f835c4a28fce2fec91e4b86946147f5c6a5555ff8
`}</code></pre>
    <h3>{`Simple Password-based Issuance`}</h3>
    <p>{`To get a certificate, run `}<inlineCode parentName="p">{`step ca certificate`}</inlineCode>{`. You'll be prompted to select a provisioner. In this example, we'll use the `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner in a password-based flow:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{` $ step ca certificate admin@yourco.com --san=any-name any.crt any.key
✔ Provisioner: pass (JWK) [kid: x6v0dwnSlz2D5opq-B5prQmyD9DhzdJSHMidiu1TjYg]
✔ Please enter the password to decrypt the provisioner key:
✔ CA: https://prod.yourco.ca.smallstep.com
✔ Certificate: any.crt
✔ Private Key: any.key
`}</code></pre>
    <p>{`Once authenticated, the authority will obtain inventory metadata and apply any templates associated with the selected provisioner. That's it. The issued certificate is ready to use.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect any.crt -short
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 3329...8201]
  Subject:     any-name
               admin@yourco.com
  Issuer:      Prod Intermediate CA
  Provisioner: pass [ID: TvGU...0zXw]
  Valid from:  2021-07-07T22:59:05Z
          to:  2021-07-08T23:00:05Z
`}</code></pre>
    <p>{`Renewing this certificate is equally straightforward:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca renew any.crt any.key --force
Your certificate has been saved in any.crt.
`}</code></pre>
    <p>{`If you inspect the newly issued certificate, you'll find it has an extended lifetime.`}</p>
    <h3>{`One-time Tokens`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner is very flexible. Under the hood, it's based on short-lived one-time tokens that are signed by a private key. The issuing authority `}<em parentName="p">{`escrows`}</em>{` an encrypted copy of the private key. In the password flow above, `}<inlineCode parentName="p">{`step`}</inlineCode>{` obtained the encrypted private key from the issuing authority and prompted for the password to decrypt it. It generated a one-time token, then immediately used it to get a certificate.`}</p>
    <p>{`Instead of broadly distributing the `}<inlineCode parentName="p">{`JWK`}</inlineCode>{` provisioner password, we can generate a token in one place (e.g., CI/CD, configuration management, or orchestration):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca token alice@yourco.com
`}</code></pre>
    <p>{`Then pass the token along for a host, container, or service to obtain a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate alice@yourco.com alice.crt alice.key --token $TOKEN
`}</code></pre>
    <h3>{`Single Sign-On`}</h3>
    <p>{`Certificates provide a secure, flexible, scalable mechanism for authenticating people, too (e.g., for SSH access, API access, or to connect to a BeyondCorp identity-aware proxy). Most organizations already have an identity provider (IdP) for authenticating people. The `}<inlineCode parentName="p">{`OIDC`}</inlineCode>{` provisioner lets you leverage authentication services from G Suite, Okta, Azure AD, and any other IdP that supports OAuth OIDC to authenticate a certificate request.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/cm-hiw-sso.svg",
        "alt": "Developer single sign on for TLS certificate",
        "title": "The certificate command triggers the OIDC Provisioner and the default browser to open the IDP login screen. The developer authenticates to the corporate single sign-on service and, upon successful completion, returns to the terminal with a personal x.509 certificate."
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect ee.crt -short
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 2676...4589]
  Subject:     developer@yourco.com
               05dc4b5d-769a-4659-92ee-9cf5d3b720b3
               https://auth.okta.com#05dc4b5d-769a-4659-92ee-9cf5d3b720b3
  Issuer:      Prod Intermediate CA
  Provisioner: okta [ID: 5310...fc2a]
  Valid from:  2021-06-30T22:52:09Z
          to:  2021-07-01T14:53:09Z
`}</code></pre>
    <h3>{`ACME`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`ACME`}</inlineCode>{` provisioner implements the `}<a parentName="p" {...{
        "href": "https://datatracker.ietf.org/doc/html/rfc8555"
      }}>{`ACME standard`}</a>{`, created and used by Let's Encrypt to secure over 260 million public websites. ACME lets you automatically get a certificate for a domain name or IP address. ACME's rich client ecosystem makes it especially easy to integrate.`}</p>
    <p>{`ACME is a simple challenge-response protocol. A client `}<em parentName="p">{`orders`}</em>{` a certificate and the authority responds with a set of `}<em parentName="p">{`challenges`}</em>{` for the client to complete to prove control over the `}<em parentName="p">{`identifier(s)`}</em>{` in the order. For example, if a client orders a certificate for a DNS identifier, the authority may challenge the client to serve a random number over HTTP from a random path to prove control over the domain name.`}</p>
    <p>{`You can use any standards-compliant ACME client to get a certificate from your Certificate Manager authorities. Here's an example workflow using Certbot, an open source ACME client maintained by the EFF:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`sudo REQUESTS_CA_BUNDLE=$(step path)/certs/root_ca.crt \\
  certbot certonly -n --standalone -d foo.internal \\
  --server https://ca.internal/acme/acme/directory
`}</code></pre>
    <p>{`ACME works almost anywhere and is especially useful for issuing certificates to web servers for use with TLS. To issue certificates for internal names that aren't in public DNS the ACME server does need access to local DNS. It's common to use a linked CA or a network-local RA. Reference the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/certificate-manager/acme"
      }}>{`Certificate Manager docs`}</a>{` for more details. `}</p>
    <h2>{`Next steps`}</h2>
    <p>{`To learn more:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/signup?product=cm"
        }}><strong parentName="a">{`Get hands-on`}</strong></a>{` - Mint your free hosted Authority in less than five minutes.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/request-demo"
        }}><strong parentName="a">{`Get advice`}</strong></a>{`- Talk to Smallstep about your project.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      